<template>
    <!--
    Компонент таблицы, стилизованной для проекта, с разбивкой на страницы
    -->

    <el-container class="dom-table" direction="vertical">
        <el-table
            :data="computedTableData"
            :default-sort="defaultSort"
            :row-key="rowKey"
            :expand-row-keys="expandRowKeys"
            border
            @sort-change="sortChange"
            @row-click="$emit('row-click', $event)"
            style="width: 100%; height: fit-content">

            <slot/>
        </el-table>

        <template>
            <slot name="mobile" :computedTableData="computedTableData"></slot>
        </template>

        <el-pagination
            background
            :current-page.sync="currentPage"
            layout="prev, pager, next"
            :page-size="rowsPerPage"
            :total="totalItems"
        />
    </el-container>

</template>
<script>
import {mapFields} from "vuex-map-fields";
import _ from "lodash";
import VueScreenSize from "vue-screen-size";

export default {
    mixins: [VueScreenSize.VueScreenSizeMixin],


    props: {
        items: {
            required: true,
            type: Array,
        },

        searchFn: {
            required: false,
            type: Function,
        },

        rowsPerPage: {
            type: Number,
            default: 10,
        },

        rowKey: {},

        expandRowKeys: {},

        defaultSort: {
            default: () => ({
                prop: null, // Имя столбца в массиве, по которому сортируем
                order: null //  ascending / descending
            })
        },
    },

    data: () => ({
        currentPage: 1,
        currentSort: {prop: null, order: null},
    }),

    computed: {
        ...mapFields("voting", {documents: 'documents'}),

        sortCol() {
            return this.currentSort.prop || this.defaultSort.prop || null
        },

        sortDir() {
            const dir = this.currentSort.order || this.defaultSort.order || null;
            if (dir == null) return null;
            return dir === 'ascending' ? 'asc' : 'desc';
        },

        tableData() {
            return this.items || []
        },

        tableDataFiltered() {
            return this.searchFn ? _.filter(this.tableData, d => this.searchFn(d) ) : this.tableData
        },

        tableDataSorted() {
            return this.sortCol ? _.orderBy(this.tableDataFiltered, this.sortCol, this.sortDir) : this.tableDataFiltered;
        },

        totalItems() {
            return this.tableDataFiltered.length
        },

        computedTableData() {
            const startIndex = (this.currentPage - 1) * this.rowsPerPage;
            const endIndex = Math.min(startIndex + this.rowsPerPage - 1, this.totalItems - 1);
            return _.slice(this.tableDataSorted, startIndex, endIndex + 1);
        },
    },

    methods: {
        sortChange({prop, order}) {
            this.currentSort.prop = prop;
            this.currentSort.order = order;
        },
    },

}
</script>
<style scoped lang="scss">
@import "~@/theme.scss";

.vote-view-link {
    color: $dom-blue;
}

.el-pagination {
    margin-top: 15px;

    ::v-deep .el-pager li {
        height: 35px;
        width: 35px;
        line-height: 35px;
        font-size: 12px;
        border: 1px solid $dom-gray;
        border-radius: 0;
    }

    ::v-deep .el-pager li.active {
        height: 35px;
        width: 35px;
        line-height: 36px;
        font-size: 12px;
        border: 0;
        border-radius: 0;
    }

    ::v-deep .btn-next {
        height: 35px;
        width: 35px;
        line-height: 35px;
    }

    ::v-deep .btn-prev {
        height: 35px;
        width: 35px;
        line-height: 35px;
    }
}

</style>

